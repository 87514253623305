import request from '@/common/utils/request'
import Vue from "vue";

export function getInvitationCode(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/invitation/getInvitationCode',
    method: 'post',
    data
  })
}

export function sendYYSMS(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/invitation/send-yy-sms',
    method: 'post',
    data
  })
}

export function sendYYEmail(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/invitation/send-yy-email',
    method: 'post',
    data
  })
}

export function sendYYSMSBatch(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/invitation/send-yy-sms-batch',
    method: 'post',
    data
  })
}

export function sendYYEmailBatch(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/invitation/send-yy-email-batch',
    method: 'post',
    data
  })
}
